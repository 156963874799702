import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import {Layout} from "../components/Layout";

export default function MtbRemmenOnderhoud() {
    return (
        <Layout restrictedWidth={false}
                pageTitle={"Je MTB remmen toe aan onderhoud?✓ | Steven's Bike Service"}
                metaDescription={"Zijn de remmen op jouw MTB toe aan onderhoud? Wij voeren dit graag voor je uit! Steven's Bike Service - dé MTB specialist van regio Deventer!✓"}>

            <div className={"fixed top-20 z-0 p-0"}>
                <StaticImage className={"landscape:w-screen portrait:h-screen"}
                             src={"../images/sbs-mtb-werkplaats.jpg"} alt={"Lekker ragguh"}/>
            </div>
            <div className={"h-[65vh]"}></div>

            <div className={"bg-white z-20 w-full"}>
                <div className=" max-w-6xl mx-auto lg:content-between gap-y-6">
                    <h2 className="text-4xl font-extrabold mt-16">Onderhoud mountainbike remmen</h2>
                    <p className={"leading-relaxed md:leading-relaxed lg:leading-relaxed mt-5 px-2 col-span-2 text:l md:text-xl lg:text-xl py-3"}>
                        De schijfremmen van je mountainbike zijn een van de belangrijkste veiligheidsvoorzieningen op je
                        fiets. Daarom is het belangrijk om je schijfremmen regelmatig te controleren en schoon te maken,
                        en ze zo nodig af te stellen.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Mountainbike remmen schoonmaken en controleren</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Door met een SCHONE doek, bijvoorbeeld een oud t-shirt je remschijven en blokken regelmatig
                        (eens in de twee weken of na elke natte rit) schoon te poetsen totdat er geen zwart meer vanaf
                        komt, zorg je ervoor dat je remmen niet gaan piepen, beter presteren en langer mee gaan.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Het is belangrijk dat de doek écht schoon is en niet al ergens anders voor is gebruikt. Anders
                        bereik je juist het tegenovergestelde: je maakt alles alleen maar vuiler waardoor je remmen
                        alleen maar slechter gaan werken.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        En als je dan toch met de remblokken bezig bent kun je gelijk controleren of er nog voldoende
                        voering op zit. Dit moet altijd meer zijn dan de dikte van het veertje tussen de blokken. Zit er
                        geen veertje tussen houd dan minimaal 0,5 mm aan.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        En tenslotte, de dikte van de remschijven is iets wat vaak wordt vergeten. Deze moeten minimaal
                        1,5 mm zijn bij de meeste merken, maar wordt ook vaak op de schijf zelf vermeld.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Mountainbike remmen inremmen</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Na het schoonmaken is het altijd goed om de MTB remmen in te remmen. Zoek een rustige plek waar
                        het verkeer geen last van je heeft, zoals bijvoorbeeld een lege parkeerplaats.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Fiets ongeveer twintig kilometer per uur en rem met één rem tot bijna stilstand. Blijf normaal
                        op de fiets zitten en het is belangrijk dat je niet helemaal tot stilstand komt of dat je wiel
                        slipt.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Dit herhaal je tot je merkt dat je rem steeds krachtiger remt en stil is. Meestal zal dat tussen
                        de tien en twintig keer zijn. Doe hetzelfde met de andere rem. Het inremmen kun je nooit te vaak doen en
                        door het na elke wasbeurt van je bike te doen, blijven ze optimaal presteren! <a
                        href={"https://www.youtube.com/watch?v=iUV2Eo9ERSk&t=19s"}
                        className={"text-blue-600 hover:underline"}
                        target={"_blank"}>Hier</a> vind je een filmpje van Sram over het inremmen.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Remvloeistof</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Verder is het belangrijk de remvloeistof tijdig te vervangen. Wanneer dit moet hangt af van hoe
                        je de MTB gebruikt en welk merk je hebt. Ga je overal vol gas naar beneden dan is je vloeistof
                        eerder aan de beurt dan wanneer je alleen lekker over de gravelpaden heen struint.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Heb je remmen met minerale olie zoals bijvoorbeeld bij Shimano of Magura, dan zijn deze gevuld met een
                        hydrofobe vloeistof. Dat wil zeggen dat de remvloeistof water afstoot en er zich dus niet mee
                        zal vermengen maar bovenop water gaat liggen. Ondanks dat het remsysteem een gesloten systeem is
                        zal er altijd vocht in komen. Daardoor is het verstandig deze zeker om de twee jaar te
                        vervangen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Heb je remmen met DOT remvloeistof zoals bijvoorbeeld Sram (voorheen Avid) of Formula, dan is de
                        vloeistof een hydrofiel. Dat wil zeggen dat het zich vermengt met water wat de remprestaties
                        ernstig vermindert. Jaarlijks deze vervangen zou dan mijn advies zijn.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Voelt je remhendel aan als een spons en lukt het niet meer om een 'stoppie' te maken dan heb je
                        waarschijnlijk lucht in je remsysteem. Soms volstaat dan een snelle ontluchting bij de
                        remhendel. Mocht dat niet het gewenste effect hebben dan zul je het hele systeem moeten
                        ontluchten, ook wel “bleeden” genoemd.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Zuigers schoonmaken en smeren</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Ook de zuigers in de remklauw hebben soms wat liefde nodig. Door deze schoon te maken en te
                        smeren met remvloeistof zorg je er voor dat de rubbers die de zuigers terug moeten trekken
                        soepel en goed blijven werken en je blokjes minder snel zullen aan blijven lopen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Haal de remblokjes uit de klauw en maakt met een doek en remmenreiniger bijvoorbeeld <a
                        href={"https://eu.muc-off.com/products/disc-brake-cleaner-400ml"}
                        className={"text-blue-600 hover:underline"}
                        target={"_blank"}>Muc off disc brake cleaner</a> de remklauw eerst schoon. Laat voorzichtig de
                        zuigers iets naar buiten komen en maak
                        deze ook schoon met remmen reiniger. Pas op dat je de zuigers niet te ver naar buiten laat
                        komen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Als je dat doet zullen ze er uit vallen, net als de remvloeistof en zal de remklauw soms
                        zelfs moeten worden vervangen. Om dit te voorkomen kun je ook een bleedblock door de helft zagen
                        en in de remklauw plaatsen. Zo voorkom je dat de zuiger te ver naar buiten kan komen.
                    </p>

                    <div className={"max-w-xs mx-auto"}>
                        <figure className="mx-auto">
                            <StaticImage src={"../images/sbs-bleed-blocks.jpg"} alt={"fietsketting"}
                                         className={"shadow-sm bg-white shadow-gray-400 mx-auto mb-2"}/>

                            <figcaption className="text-center">
                                Een doormidden gezaagde bleedblock.
                            </figcaption>
                        </figure>
                    </div>

                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Nu kun je makkelijk met een wattenstaafje en wat remvloeistof (let wel op dat je de remvloeistof gebruikt
                        waar ook je remsysteem mee is gevuld) en smeer de zuiger en daarmee ook het afdichtingsrubber.
                        Haal met een doek de overtollige remvloeistof weg en plaats je remblokken weer terug in de
                        klauw.
                    </p>
                </div>
            </div>
        </Layout>
    )
}
